import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import tw, { css, theme } from 'twin.macro'
import ArticleGrid from '../../components/article-grid'
import Banner from '../../components/banner'
import FeaturedArticle from '../../components/featured-article'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import SubNav from '../../components/sub-nav'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'

const NewsPage = ({ data: { news } }) => {
  const [activeLink, setActiveLink] = useState('#featured')
  const page = useMemo(() => flatten(news || {}, ['hero', 'featured', 'banner']), [news])

  const anchorLinks = useMemo(
    () => [
      {
        label: get(page, 'newsArticlesLabel'),
        link: '#news',
        active: activeLink === '#news',
      },
    ],
    [activeLink, page]
  )

  const articles = useMemo(
    () =>
      (get(page, 'newsArticles') || []).map((release) => ({
        tag: release.typeOfArticle,
        date: release.date,
        headline: release.headline,
        excerpt: get(release, 'contentNode.childMarkdownRemark.excerpt'),
        callToAction: {
          label: get(page, 'newsArticlesButtonLabel'),
          link: `/news/${release.slug}`,
        },
      })),
    [page]
  )

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
      headerTransition={true}
      headerTransitionColor="white"
    >
      <section id="hero" css={tw`relative bg-secondary-500`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          titleHeadingType="h1"
          description={get(page, 'hero.descriptionNode')}
          style={css`
            h3 {
              ${tw`font-light`}
            }
          `}
        />
      </section>
      <SubNav
        links={anchorLinks}
        style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-mobile-header lg:top-desktop-header`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
        `}
        selectBackground={theme`colors.white`}
        currentPath={activeLink}
      />
      <InView
        as="section"
        id="featured"
        rootMargin="-128px 0% -80% 0%"
        css={tw`bg-secondary-500 bg-opacity-5`}
        onChange={(view) => {
          if (view) setActiveLink('#featured')
        }}
      >
        <div css={global`layout.container`}>
          <FeaturedArticle
            date={get(page, 'featured.date')}
            headline={get(page, 'featured.headline')}
            excerpt={get(page, 'featured.contentNode.childMarkdownRemark.excerpt')}
            callToAction={{
              label: get(page, 'featuredButtonLabel'),
              link: `/news/${get(page, 'featured.slug')}`,
            }}
            image={get(page, 'featured.image')}
          />
        </div>
      </InView>

      {articles.length > 0 && (
        <InView
          as="section"
          id="news"
          css={tw`pt-20`}
          rootMargin="-128px 0% -80% 0%"
          onChange={(view) => {
            if (view) setActiveLink('#news')
          }}
        >
          <ArticleGrid
            articles={articles}
            title={get(page, 'newsArticlesLabel')}
            relatedNewsLabel={get(page, 'relatedNewsLabel')}
            numArticles={3}
          />
        </InView>
      )}
      <section id="call-to-action" css={[global`layout.container`, tw`pt-32 pb-7`]}>
        <Banner
          title={get(page, 'banner.titleNode')}
          subtitle={get(page, 'banner.subtitleNode')}
          description={get(page, 'banner.descriptionNode')}
          callToAction={get(page, 'banner.callToAction')}
        />
      </section>
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    news: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default NewsPage

export const query = graphql`
  query NewsQuery($locale: String!) {
    news: datoCmsHkGiNews(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredLabel
      featuredButtonLabel
      featured {
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      newsArticlesLabel
      newsArticlesButtonLabel
      newsArticles {
        typeOfArticle
        slug
        date
        headline
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      relatedNewsLabel
      banner {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
    }
  }
`
