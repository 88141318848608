import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import ReactPlayer from 'react-player/youtube'
import { global } from '../styles/global'
import { format } from '../utils/date'
import Button from './button'
import Heading from './heading'
import Image from './image'
import VideoPlayer from './video-player'
import { ReactComponent as Play } from '../images/icon-play-sm-solid.svg'

const FeaturedArticle = ({
  type,
  date,
  headline,
  excerpt,
  noHero,
  callToAction,
  image,
  video,
  embedVideo,
  poster,
}) => {
  return (
    <article css={[global`layout.grid`, tw`items-center py-20 md:py-32 lg:py-24`]}>
      <div
        css={tw`flex flex-col items-start justify-center mt-6 col-span-4 md:col-span-8 lg:(col-span-5 mt-0)`}
      >
        <div
          css={[
            global`typography.subtitle`,
            tw`mb-4 text-base font-bold capitalize text-primary-300`,
          ]}
        >
          {type ? `${type} - ` : undefined}
          <time>{format(date, 'dd MMMM yyyy')}</time>
        </div>
        {noHero ? (
          <Heading style={tw`font-bold text-primary-500`} content={headline} headingType="h2" />
        ) : (
          <Heading style={tw`font-bold text-primary-500`} content={headline} headingType="h3" />
        )}
        <Heading style={tw`mt-6 font-normal`} content={excerpt} headingType="h6" />
        {callToAction && (
          <Button
            style={tw`mt-12`}
            type="primary"
            size="base"
            theme="navy-cyan"
            label={callToAction.label}
            link={callToAction.link}
          />
        )}
      </div>
      {image && (
        <Image
          image={image}
          style={tw`row-start-1 col-span-4 md:col-span-8 lg:(ml-12 row-start-auto col-span-7) xl:col-span-11`}
        />
      )}
      {video && (
        <VideoPlayer
          streamingUrl={video.video.mp4Url}
          thumbnailUrl={video.video.mp4Url}
          style={tw`row-start-1 col-span-4 md:col-span-8 lg:(ml-12 row-start-auto col-span-7) xl:col-span-11`}
        />
      )}
      {embedVideo && (
        <div
          css={tw`w-full h-0 pb-ratio-16/9 relative row-start-1 col-span-4 md:col-span-8 lg:(ml-12 row-start-auto col-span-7) xl:col-span-11`}
        >
          <ReactPlayer
            url={embedVideo.url}
            light={poster.url}
            playIcon={
              <button>
                <Play />
              </button>
            }
            playing
            width="100%"
            height="100%"
            loop
            controls
            muted
            playsinline
            style={tw`absolute top-0 w-full h-full`}
          />
        </div>
      )}
    </article>
  )
}

FeaturedArticle.propTypes = {
  type: PropTypes.string,
  date: PropTypes.string,
  headline: PropTypes.string,
  excerpt: PropTypes.string,
  noHero: PropTypes.bool,
  callToAction: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
  image: PropTypes.object,
  poster: PropTypes.object,
  video: PropTypes.object,
  embedVideo: PropTypes.object,
}

export default FeaturedArticle
